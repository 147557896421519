import React from "react";
import oldbuild from '../assets/oldbuild.webp';
import p1 from '../assets/homegallery/img01.webp'
import p2 from '../assets/homegallery/img02.webp'
import p3 from '../assets/homegallery/img03.webp'
import p4 from '../assets/homegallery/img04.webp'
import p5 from '../assets/homegallery/img05.webp'
import p6 from '../assets/homegallery/img06.webp'
import p7 from '../assets/homegallery/img07.webp'
import p8 from '../assets/homegallery/img08.webp'
import p9 from '../assets/homegallery/img09.webp'
import pics from '../assets/dpics.svg'  

const Galleryframe=()=>{
    return (
        <div className="flex flex-col bg-white drop-shadow-xl justify-center items-center rounded-2xl p-4 my-4 mx-8">

            <div className='flex flex-row justify-center gap-2 flex-wrap'>
            <img className='scaleup p-3 md: w-[15rem]' src={oldbuild} alt="ratholsavam"/>
                <img className='scaleup p-3 md: w-[15rem]' src={p1} alt="kalpathi theru"/>
                <img className='scaleup p-3 md: w-[15rem]' src={p2} alt="kalpathy"/>
                <img className='scaleup p-3 md: w-[15rem]' src={p3} alt="kalpathi ratham "/>
                <img className='scaleup p-3 md: w-[15rem]' src={p4} alt="kalpathi village "/>
                <img className='scaleup p-3 md: w-[15rem]' src={p5} alt="kalpathy agraharam "/>
                <img className='scaleup p-3 md: w-[15rem]' src={p6} alt="kalpathy "/>
                <img className='scaleup p-3 md: w-[15rem]' src={p7} alt="kalpathi car festival "/>
                <img className='scaleup p-3 md: w-[15rem]' src={p8} alt="kalpathy temples"/>
                <img className='scaleup p-3 md: w-[15rem]' src={p9} alt="kalpathy heritage village "/>
            </div>

            <a target="_blank" rel="noreferrer" href="https://drive.google.com/drive/folders/15OpLtAi93EIbFfZCqx2cb_uB-76v0Eac"><button className='bg-yellow-500 py-4 text-center items-center text-white w-full px-8 flex flex-row gap-5 my-5 justify-center md:w-auto rounded-full'>View full Gallery<img src={pics} alt="btn"/></button></a>
                    
        </div>
    );
}
export default Galleryframe;