import { Link,useNavigate } from "react-router-dom";
import React from "react";
import Nav from "../components/Nav";
import kpt from '../assets/kpt-logo.svg'
import home from '../assets/home.svg'
import { Helmet } from "react-helmet";

export default function NotFound() {

    const navigate = useNavigate(); 
    return (
        <div>
            <Nav/>
            <Helmet>
                <title>Not found • 404</title>
                <meta
                name="description"
                content="Looks like you've lost"
                />
            </Helmet>
            <div className="flex items-center justify-center flex-col gap-y-4"> 
                <img className="w-1/4 md:w-1/5" src={kpt} alt='kalpathylogo'></img>
                <p className="text-maroon-500 text-xl text-center ">OOPS! The page you requested was not found<br></br></p>
                <div className="flex items-center justify-center  flex-col md:flex-row">
                    <Link to="/"><button className="inline-flex items-center rounded bg-yellow-500 py-2.5 p-3 text-white md:px-5 text-xs"><img className="pr-2 pt-0.5" src={home} alt='homelogo'></img> Go Home </button></Link>
                    <p className="p-4 text-xs">or</p>
                    <button onClick={() => navigate(-1)} className="p-4 rounded px-5 md: border-solid border-2 border-slate-300 py-2.5 text-sm text-gray-700">Back</button>
                </div>
            </div>
            
        </div>
    )
}