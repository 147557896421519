import Nav from '../components/Nav'
import Footer from '../components/Footer'
// import Sticky from '../components/Sticky'
// import Cards from '../components/Cards'
// import temple from '../assets/temple.webp'
// import radham from '../assets/radham.webp'
import oldvsnew from '../assets/oldvsnew.webp';
import oldbuild from '../assets/oldbuild.webp';
import btn from '../assets/btn.svg';
import { Link } from 'react-router-dom'
import pics from '../assets/pics.svg'
import dpics from '../assets/dpics.svg'
import god1 from '../assets/gods/gods01.webp'
import god2 from '../assets/gods/gods02.webp'
import god3 from '../assets/gods/gods03.webp'
import god4 from '../assets/gods/gods04.webp'
import god5 from '../assets/gods/gods05.webp'
import god7 from '../assets/gods/gods07.webp'
import god6 from '../assets/gods/gods06.webp'
import god8 from '../assets/gods/gods08.webp'
import p1 from '../assets/homegallery/img01.webp'
import p2 from '../assets/homegallery/img02.webp'
import p3 from '../assets/homegallery/img03.webp'
import p4 from '../assets/homegallery/img04.webp'
import p5 from '../assets/homegallery/img05.webp'
import p6 from '../assets/homegallery/img06.webp'
import p7 from '../assets/homegallery/img07.webp'
import p8 from '../assets/homegallery/img08.webp'
import p9 from '../assets/homegallery/img09.webp'
import { Helmet } from "react-helmet";
import { useEffect } from "react";

//heads is a class name to generate headings styles in this file

const Home = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        document.querySelector('body').scrollTo(0,0)
    }, []);

    // const cards = [{"img": temple, "title": "Sree Visalakshi Sametha Shree Viswanatha Swamy Temple, Palakkad, Kerala, 678003", "sub": "", "col":"bg-maroon-100", "border":"border-maroon-500", "isDirection": true}, {"img": radham, "title": "Ratholsavam 2019", "sub": "is an annual Temple festival in the Kalpathy, Palakkad", "col": "bg-yellow-100", "border":"border-yellow-500", "isDirection": false}, {"img": radham, "title": "Ratholsavam 2018", "sub": "is an annual Temple festival in the Kalpathy, Palakkad", "col": "bg-maroon-100", "border":"border-maroon-500", "isDirection": false} ]

    return(
        <div>
            <Nav/>
            <Helmet>
                <title>Kalpathy • Sree Visalakshi Sametha Shree Viswanatha Swamy Temple</title>
                <meta
                name="description"
                content="Kundambalam - 15th-century Hindu temple dedicated to Lord Shiva is the site of the Kalpathi Ratholsavam"
                />
                <meta
                name="keywords"
                content="temple, old, art, architecture, palakkad, kerala, tradition, ratholsavam, kalpathy"
                />
            </Helmet>

                {/* <div className=' md:flex scrlbr flex p-5 gap-5 shrink-0 m-4 overflow-x-scroll'>
                    {
                        cards.map((card, index) => {
                            return <Cards key={index} image={card.img} title={card.title} sub={card.sub} col={card.col} border={card.border} isDirection={card.isDirection}/>
                        })
                    }
                </div> */}

                <div className='flex flex-row mt-12 flex-wrap md:flex-nowrap m-5 justify-center items-center'>
                    <img className='w-fit p-4 md:w-1/2 bg-white rounded drop-shadow-xl m-2' src={oldvsnew} alt="Kalpathy Temple"/>
                    <div className='w-auto p-4' >
                        <p className='heads text-lg'>Kalpathy Renaissance</p>
                        <p className="text-base pt-3">Sri Visalakshi Sametha Sri Viswanathaswamy temple, popularly known as Kasi Viswanathaswamy Temple or locally as Kundukovil(Kundambalam) is a famous Hindu temple located in the Kalpathy village of Palakkad in Kerala, India. It is the site of the annual Kalpathi Ratholsavam which is one of the most famous temple festivals of Kerala. It is a 15th Century temple which is being maintained by members of the V K Edam. The most recent renovation work was happened in 2006, which has changed the total structure of the Temple. Read more about the Temple's History below ↓</p>
                    </div>
                </div>

                <div className='flex justify-center mt-12 bg-white drop-shadow-xl flex-row flex-wrap-reverse md:flex-nowrap '>
                    <div className=' flex flex-col justify-evenly md: w-auto px-4 mt-4 mx-5' >
                        <div>
                            <p className='heads text-lg'>History Of Kalpathy</p>
                            <p className="text-base pt-3 text-justify">Temples are numerous in Palakkad. One being attached to each of the twenty gramams in the town. The Chief and most important as well as the most richly endowed is the Kalpathy Temple dedicated to Shiva a low quadrangular building on the banks of the river of that name. The Car festival held in November is the biggest in the taluks and collects more than 20,000 people. The Siva Temple mentioned in the Malabar Gazetteer is the Temple dedicated to Sree Visalakshi Sametha Sree Viswanathaswamy, popularly known as "KASI VISWANATHASWAMY TEMPLE". This Temple is situated on the south banks of the Neela River called also as Kalpathy River (a tributory of Bharathapuzha) in the midst of the four Brahimn Agraharams- New Kalpathy, Old Kalpathy, Chathapuram and Govindarajapuram</p>
                        </div>
                        <Link to="/about"><button className='bg-yellow-500 py-5 text-center text-white w-full px-8 flex flex-row gap-5 my-5 justify-center md:w-auto rounded-full'>Read full History<img src={btn} alt="btn"/></button></Link>
                    </div>
                    <img className=' p-5 md: w-min' src={oldbuild} alt="Kalpathy"/>
                </div>

                <section className='my-12'>
                    <div className='w-auto p-4' >
                        <p className='m-5 heads text-lg'>Deities Of Kundambalam</p>
                        <div className=' flex flex-row gap-8 p-1 mt-4 overflow-x-scroll  scrlbr mx-5'>
                            
                            <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className=' border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god3} alt='kalpathy viswanathar' />
                                <p>Kasi Viswanathar</p>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className='border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god2} alt='kalpathy visalakshi' />
                                <p>Visalakshi</p>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className='border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god1} alt='kalpathy vinayakar' />
                                <p>Vinayakar</p>
                            </div>
                             <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className='border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god4} alt='kalpathy murugan' />
                                <p>Murugan</p>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className='border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god8} alt='kalpathy navagraham' />
                                <p>Navagraham</p>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className='border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god5} alt='kalpathy dhakshinamoorthy' />
                                <p>Dhakshinamoorthy</p>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className='border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god7} alt='kalpathy gangadharan' />
                                <p>Gangadharan</p>
                            </div>
                            <div className='flex flex-col justify-center items-center gap-3 shrink-0'>
                                <img className='border-solid border-white border-[3px] rounded-full scaleup w-[5rem] md:w-[6rem] drop-shadow-lg' src={god6} alt='kalpathy bhairavar' />
                                <p>Bhairavar</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className=' my-12 justify-center bg-white drop-shadow-xl flex-col flex-wrap-reverse md:flex-nowrap flex '>
                    <div className='md: w-auto px-4 mt-4 ml-5 flex flex-row justify-between  items-center ' >
                        <p className='heads text-lg shrink-0'>Photo Gallery</p>
                        <Link to="/gallery"><button className='pc shrink-0 text-green-500 py-3 px-5 flex flex-row gap-2 items-center justify-center'>View All photos<img src={pics} alt="btn"/></button></Link>
                    </div>

                    <div className='flex flex-row gap-2 py-1 pl-5  overflow-x-scroll scrlbr'>
                        <img className='scaleup p-3 md: w-[15rem]' src={oldbuild} alt="ratholsavam"/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p1} alt="kalpathi theru"/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p2} alt="kalpathy"/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p3} alt="kalpathi ratham "/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p4} alt="kalpathi village "/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p5} alt="kalpathy agraharam "/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p6} alt="kalpathy "/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p7} alt="kalpathi car festival "/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p8} alt="kalpathy temples"/>
                        <img className='scaleup p-3 md: w-[15rem]' src={p9} alt="kalpathy heritage village "/>
                    </div>
                    <Link to="/gallery"><button className='mob bg-yellow-500 py-5 text-center items-center text-white w-[85%] m-auto px-8 flex flex-row gap-3 mt-2 justify-center rounded-full '>View All photos<img src={dpics} alt="btn"/></button></Link>
                </div>

            <Footer/>
        </div>
    )
}
export default Home;
