import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Jsondata from "../components/JSONdatadisplay.jsx"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Poojas = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        document.querySelector('body').scrollTo(0,0)
    }, []);

    return(
            <div>
                <Nav/>
                <Helmet>
                <title>Poojas • Kalpathy Shivan temple</title>
                <meta
                name="description"
                content="Poojas that are conducted at Kalpathy Kundambalam"
                />
                <meta
                name="keywords"
                content="temple, old, art, architecture, palakkad, kerala, tradition, ratholsavam, kalpathy"
                />
            </Helmet>
                <p className="text-4xl text-center font-semibold text-yellow-500 p-5" >Poojas</p>
                <div className="paragraph m-auto text-center">
                    <p className="m-4 px-3 text-base md:m-4 pb-5"> The poojas are conducted in the daily basis, and they are being performed on the pattern of the pooja held in the
                        Mayurnathar Temple situated in Mayavaram, Tamil Nadu. <br></br> <Link to = "/about" className="text-maroon-500 font-semibold">Read more....</Link> <br></br>Here is the list of Poojas ↓
                    </p>
                </div>
                <Jsondata/>
                <Footer/>
            </div>
        )
    }
export default Poojas;