import { Link } from 'react-router-dom';
//Navbar for bottom
const Nav = () => {

    return(
        <div className='flex justify-between items-center'>
        <div className='flex hidden p-4 justify-between items-center md:flex'>
            <section className='pl-3 flex font-medium text-base text-red-500 gap-x-6 pr-4' >
                <Link to='/'><p className='text-green-500 hover:text-maroon-500'>Home</p></Link> /
                <Link to='/about'><p className='text-green-500 hover:text-maroon-500'>About</p></Link> /
                {/*<Link to='/festivals'><p className='text-green-500 hover:text-maroon-500'>Festivals</p></Link> /*/}
                <Link to='/gallery'><p className='text-green-500 hover:text-maroon-500'>Gallery</p></Link> /
                <Link to='/poojas'><p className='text-green-500 hover:text-maroon-500'>Poojas</p></Link> /
                <Link to='/contact'><p className='text-green-500 hover:text-maroon-500 active:font-semibold'>Contact</p></Link> 
            </section>
        </div>
        </div>

    )
}

export default Nav;