import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Galleryframe from "../components/Galleryframe";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Gallery = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        document.querySelector('body').scrollTo(0,0)
    }, []);

    return(
        <div>
            <Helmet>
                <title>Gallery • Kalpathy Shivan temple</title>
                <meta
                name="description"
                content="Go through the memorable moments of the Kalpathy Kundambalam"
                />
                <meta
                name="keywords"
                content="temple, old, art, architecture, palakkad, kerala, tradition, ratholsavam, kalpathy"
                />
            </Helmet>
            <Nav/>
            <Galleryframe />

            <Footer/>
        </div>
    )
}
export default Gallery;