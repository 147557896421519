import strip from '../assets/strip.svg'
import mstrip from '../assets/strip-mob.svg'

const Strip = () => {
    return(
        <div className='pointer-events-none'>
            <img className= 'w-screen hidden md:flex' src={strip} alt='traditional-strip' ></img>
            <img className='w-screen flex md:hidden' src={mstrip} alt='strip'></img>
        </div>
    )
}

export default Strip;