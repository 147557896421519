import Nav from "../components/Nav";
import Footer from "../components/Footer";
import mail from '../assets/gmail.svg'
import wa from  '../assets/whatsapp.svg'
import divi from '../assets/divi.svg'
// import gpay from '../assets/gpay.png'
import insta from '../assets/insta.svg'
import fb from '../assets/fb.svg'
import yt from '../assets/yt.svg'
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Contact = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        document.querySelector('body').scrollTo(0,0)
    }, []);

    return(
        <div>
            <Nav/>
            <Helmet>
                <title>Contact • Kalpathy Shivan temple</title>
                <meta
                name="description"
                content="Contact the officials of Kalpathy Kundambalam"
                />
                <meta
                name="keywords"
                content="temple, old, art, architecture, palakkad, kerala, tradition, ratholsavam, kalpathy"
                />
            </Helmet>
            <div className="p-8">
                <h1 className="text-2xl text-center heads pt-5" >Contact Us</h1>
                <p className="text-base text-center text-green-500 pt-4">Call us between 06:00AM to 10:00AM & 05:00PM to 08:00PM</p>
                <ul className="text-lg text-center flex flex-col break-words gap-2 text-maroon-500 font-semibold p-5">
                    <a href="tel:04912576575" target="_blank" rel="noreferrer" >Office : <u className="text-green-500">0491-2576575</u></a>
                </ul>
                <div className="flex flex-row gap-8 pt-6 justify-center">
                    <a href="https://wa.me/919809167322" target="_blank" rel="noreferrer" ><button className="scaleup flex flex-row justify-center items-center gap-3 py-3 border-yellow-100 border-solid border-2 rounded-2xl px-6 bg-white"><img className="w-1/4 " src={wa} alt='whatsapp' />Whatsapp</button></a>
                    <a href="mailto:kalpathysvsvsd@gmail.com" ><button className="scaleup flex flex-row justify-center items-center gap-3 py-3  border-yellow-100 border-solid border-2 rounded-2xl px-6 bg-white"><img className="w-1/3 " src={mail} alt='Gmail' />Gmail</button></a>
                </div>
            </div>
            <img className="pt-4 opacity-30 w-screen pointer-events-none" src={divi} alt='divider'/>

            <div className="p-8">
                <h1 className="text-2xl text-center heads pt-5" >Temple Timings</h1>
                <p className="font-normal text-center  text-sm pt-2">* The Temple timings may differ in Festival Times</p>
                <ul className="text-lg text-center flex flex-col gap-2 text-maroon-500 font-semibold p-5">
                    <p className="text-orange-500 font-normal ">POOJA TIMING</p>
                    <p>Morning : <span className="text-green-500">05:00 AM to 10:00 AM</span></p>
                    <p>Evening : <span className="text-green-500">05:00 PM to 08:00 PM</span></p>
                </ul>
                <ul className="text-lg text-center flex flex-col gap-2 text-maroon-500 font-semibold p-5">
                    <p className="text-orange-500 font-normal ">WORSHIP TIMING</p>
                    <p>Morning : <span className="text-green-500">07:00 AM to 09:30 AM</span></p>
                    <p>Evening : <span className="text-green-500">05:00 PM to 07:30 PM</span></p>
                </ul>
            </div>
            <img className="pt-4 opacity-30 w-screen pointer-events-none" src={divi} alt='divider'/>

            {/*<div className="p-8 ">
                <h1 className="text-2xl text-center heads pt-5" >Donations</h1>
                <p className="text-base text-center text-green-500 pt-4">This valuable service requires support from Members like you, Currently we support GPay Only (Also Get a Reciept of payment)</p>
                <div className="flex flex-row gap-8 pt-6 justify-center">
                <a href="upi://pay?pa=harimohan0502@okaxis&pn=Hari%20Prasad%20Designer&aid=uGICAgIDLmKjyIA" ><button className="flex flex-row justify-center items-center gap-3 py-3 border-yellow-100 border-solid border-2 rounded-2xl px-6 bg-white"><img className="w-3/4 " src={gpay} alt='gpay'/></button></a>
            </div>
            
            </div>
            <img className="pt-4 opacity-30 w-screen" src={divi} alt='divider'/>*/}
            
            <div id="#donations" className="p-8  pb-12">
                <h1 className="text-2xl text-center heads pt-5" >Social Handles</h1>
                <p className="text-base text-center text-green-500 pt-4">Don't miss our content in these Unofficial Social Handles, We are grateful for what they're doing</p>
                <p className="text-base text-center text-yellow-500 ">Tag #Kalpathy & Locate Kalpathy on Social Media to get a chance to be  featured</p>
            <div className="flex flex-row gap-x-8 gap-y-3 pt-6 justify-center flex-wrap">
                <a href="https://www.facebook.com/KalpathyVillage/" target="_blank" rel="noreferrer"  ><button className="scaleup flex flex-row justify-center items-center gap-3 py-3 border-yellow-100 border-solid border-2 rounded-2xl px-6 bg-white"><img className="w-1/4 " src={fb} alt='facebook' />Facebook</button></a>
                <a href="https://instagram.com/kalpathygram" target="_blank" rel="noreferrer" ><button className="scaleup flex flex-row justify-center items-center gap-3 py-3  border-yellow-100 border-solid border-2 rounded-2xl px-6 bg-white"><img  className="w-1/4 " src={insta} alt='instagram' />Instagram</button></a>
                <a href="https://www.youtube.com/channel/UCA8hfjYKWoyvbRl4M04HaBg" target="_blank" rel="noreferrer" ><button className="scaleup flex flex-row justify-center items-center gap-3 py-3  border-yellow-100 border-solid border-2 rounded-2xl px-6 bg-white"><img  className="w-1/4 " src={yt} alt='youtube' />YouTube</button></a>
            </div>
            </div>

            <div className=" text-center mb-5">
                <a href="/disclaimer.html" className="text-maroon-500 text-sm pr-5 shrink-0 underline hover:text-yellow-500" >Disclaimer</a>
                <a href="https://wa.me/919809167322" target="_blank" rel="noreferrer" className="text-maroon-500 text-sm shrink-0 underline hover:text-yellow-500">Report or Feedback</a>
            </div>
            <Footer/>
        </div>
    )
}
export default Contact;
