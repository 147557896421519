import NavB from './NavB'

const Footer = () => {

    return(
        <div className=''>
        <div className='bottom-0 w-full'>
            <NavB/>
            <div className="py-3 text-xs px-7 text-center bg-maroon-500 flex flex-col text-white  md:flex-row justify-between text-xl gap-y-3">
                <a href="https://devignx.hariprasd.me" target="_blank" rel='noreferrer' className="text-white text-xs underline md: shrink-0 hover:text-maroon-100"> Crafted with ♡ by Devign Experience</a>
                <p className=" w-full text-white text-xs md:text-right w-auto">© 2022  Sree Visalakshi Sametha Shree Viswanatha Swamy Temple. All Rights Reserved</p>
            </div>
        </div>
        </div>
    )
}
export default Footer;
