import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery'
import Poojas from './pages/Poojas'
import NotFound from './pages/NotFound';
import ScrollToTop from './Scroll';

function App() {

  return (
    <div>
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route element={<Home/>} path="/"/>
          <Route element={<About/>} path="/about"/>
          <Route element={<Contact/>} path="/contact"/>
          <Route element={<Gallery/>} path="/gallery"/>
          <Route element={<Poojas/>} path="/poojas"/>
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
