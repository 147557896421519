import logo from '../assets/logo.svg'
import Strip from './Strip';
import menu from '../assets/menu.svg'
import { Link} from "react-router-dom";
import React, { useState, useEffect } from "react";


const Nav = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [popup,setPop] = useState(false);
    
    const handleClickOpen = () => {
        setPop(!popup);
    }
    const closePopup = () => {
        setPop(!popup);
    }

    return(
        <div>
            <Strip/>
            <div className='flex p-4  justify-center items-center lg:justify-between flex'>  
                <Link to='/'><img className='w-52 md:w-64 pl-4 pointer-events-none' src={logo} alt='Logo'></img></Link>
                <section className='flex hidden font-medium text-xl text-maroon-500 gap-x-11 pr-4 lg:flex' >
                    
                    <Link to='/'><p className='cpage hover:text-green-500  hover:underline hover:underline'>Home</p></Link> 
                    <Link to='/about'><p className='hover:text-green-500  hover:underline'>About</p></Link> 
                    {/*<Link to='/festivals'><p className='hover:text-green-500  hover:underline'>Festivals</p></Link>*/} 
                    <Link to='/gallery'><p className='hover:text-green-500  hover:underline'>Gallery</p></Link>
                    <Link to='/poojas'><p className='hover:text-green-500  hover:underline'>Poojas</p></Link> 
                    <Link to='/contact'><p className='hover:text-green-500  hover:underline'>Contact</p></Link> 

                </section>
            </div>
            <div onClick={handleClickOpen} className='cursor-pointer flex fixed bottom-[5%] toppp drop-shadow-xl right-[10%] mob'><img src={menu} alt='menu'></img></div>
            <div>
                { popup?
                <div>
                    <div className='fixed bottom-0 mob shadow-inner toppp bg-white p-5 flex flex-col gap-3 justify-center items-center w-screen'>
                        <p onClick={closePopup} className='cursor-pointer bg-yellow-500 px-4 font-light py-1 text-2xl rounded-full text-white'>x</p>
                        <div className='text-gray-400'>Select any Page</div>
                        <section className='flex text-center text-maroon-500 font-semibold flex-col gap-3 text-xl'>
                            <Link to = "/"><div>Home</div></Link>
                            <Link to = "/about"><div>About</div></Link>
                            <Link to = "/gallery"><div>Gallery</div></Link>
                            <Link to = "/poojas"><div>Poojas</div></Link>
                            <Link to = "/contact"><div>Contact US</div></Link>
                        </section>
                    </div>
                </div>:""}
            </div>
        </div>
    )
}

export default Nav;